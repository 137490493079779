<template>
  <div class="card">
    <div class="title">{{ translations.estimatedPrice.title }}</div>

    <div class="row">
      <div class="label">
        {{ translations.estimatedPrice.perMonth }}
      </div>
      <div class="amount">
        {{ formatNumber(estimatedPrice.perMonth) }}
      </div>
      <div class="currency">
        NOK
      </div>
    </div>

    <div class="row">
      <div class="label">
        {{ translations.estimatedPrice.perYear }}
      </div>
      <div class="amount">
        {{ formatNumber(estimatedPrice.perYear) }}
      </div>
      <div class="currency">
        NOK
      </div>
    </div>

    <div class="comment">
      {{ translations.estimatedPrice.exVat }}
    </div>

    <div class="button-wrapper">
      <Button :text="translations.estimatedPrice.contactManager" :icon="require('@/assets/envelop.svg')"/>
    </div>

  </div>
</template>

<script>
import {translations} from "@/utils";
import Button from "@/components/Button";

export default {
  name: 'EstimatedPrice',
  components: { Button },
  props: {
    estimatedPrice: {
      required: true
    },
  },
  data() {
    return {
      translations: translations,
    }
  },
  methods: {
    formatNumber(number) {
      return new Intl.NumberFormat('en-US').format(number);
    }
  }
}
</script>

<style lang="scss" scoped>
#price-calculator {
  .title {
    display: block;
    font-weight: 700;
    margin-bottom: calc(var(--rem) * 8);
  }

  .row {
    flex-basis: 100%;
    align-items: center;
    display: flex;
    margin-top: calc(var(--rem) * 21);
    padding-bottom: calc(var(--rem) * 24);
    margin-bottom: calc(var(--rem) * 10);
    border-bottom: 1px solid #CBD2E9;

    .label {
      color: #1C1B1C;
      flex-basis: 100%;
      text-align: left;
    }

    .amount {
      color: black;
      font-size: calc(var(--rem) * 36);
    }

    .currency {
      align-self: flex-start;
      font-size: calc(var(--rem) * 12);
      margin-top: calc(var(--rem) * 5);
      margin-left: calc(var(--rem) * 8);
    }
  }

  .comment {
    flex-basis: 100%;
    margin-top: calc(var(--rem) * 24);
    margin-bottom: calc(var(--rem) * 24);
    color: #8B95B7;
    text-align: right;
    font-style: italic;
  }

  .button-wrapper {
    flex-basis: 100%;
  }
}
</style>
