<template>
  <div class="initial-styles" id="price-calculator">
    <Header/>
    <CompanyInformation
        :open="currentStep === 1"
        @dataChanged="companyChanged"
        @next="goToStep(2)"/>

    <WhoDoesWhat
        v-if="company && currentStep > 1"
        :open="currentStep === 2"
        :company="company"
        :who-does-what="whoDoesWhat"
        @dataChanged="setWhoDoesWhat"/>

    <div class="additional-info" v-if="additionalInfoRequired && currentStep > 1">
      {{ translations.additionalInformation }}
    </div>

    <VoucherInfo
        v-if="whoDoesWhat['vouchers'] !== null && currentStep > 1"
        :open="currentStep === 2"
        :type="whoDoesWhat['vouchers']"
        :voucher-info="voucherInfo"
        @dataChanged="setVoucherInfo"/>

    <SalaryInfo
        v-if="whoDoesWhat['salaries'] && currentStep > 1"
        :open="currentStep === 2"
        :salary-info="salaryInfo"
        @dataChanged="setSalaryInfo"/>

    <ShareholderInfo
        v-if="whoDoesWhat['shareholderReport'] && currentStep > 1"
        :open="currentStep === 2"
        :shareholder-info="shareholderInfo"
        @dataChanged="setShareholderInfo"/>

    <div class="additional-info" v-if="hasAllRequiredInfo && currentStep > 1">
      <Button :text="translations.calculatePrice" :icon="require('@/assets/calculator.svg')" @click="goToStep(3)"/>
    </div>

    <EstimatedPrice :estimated-price="estimatedPrice" v-if="currentStep === 3 "/>

  </div>
</template>

<script>
import {translations, calculatePrice} from "@/utils";
import Button from "@/components/Button";
import CompanyInformation from "@/components/CompanyInformation";
import EstimatedPrice from "@/components/EstimatedPrice";
import Header from '@/components/Header.vue';
import SalaryInfo from "@/components/SalaryInfo";
import ShareholderInfo from "@/components/ShareholderInfo";
import VoucherInfo from "@/components/VoucherInfo";
import WhoDoesWhat from "@/components/WhoDoesWhat";

export default {
  name: 'app',
  components: {
    Button,
    CompanyInformation,
    EstimatedPrice,
    Header,
    SalaryInfo,
    ShareholderInfo,
    VoucherInfo,
    WhoDoesWhat,
  },
  computed: {
    additionalInfoRequired() {
      return this.whoDoesWhat['vouchers'] !== null || this.whoDoesWhat['salaries'] || this.whoDoesWhat['shareholderReport']
    },
    hasAllRequiredInfo() {
      const whoDoesWhatArray = Object.values(this.whoDoesWhat);
      return whoDoesWhatArray.filter((item) => item !== null).length === whoDoesWhatArray.length;
    },
  },
  data() {
    return {
      company: null,
      whoDoesWhat: {
        vouchers: null,
        salaries: null,
        vatStatement: null,
        yearlyAccounts: null,
        taxReport: null,
        shareholderReport: null,
      },
      voucherInfo: {
        number: 0,
        complexAccounting: false,
        cashSales: false,
        postingRevisions: "continuous",
      },
      salaryInfo: {
        paycheckNumber: 0,
        claimsNumber: 0,
      },
      shareholderInfo: {
        numberOfShareholders: 0,
      },
      estimatedPrice: {
        perMonth: 0,
        perYear: 0,
      },
      currentStep: 1,
      translations: translations,
    }
  },
  methods: {
    companyChanged(company) {
      this.company = company;
      let whoDoesWhat = {};
      whoDoesWhat["vouchers"] = null;
      if (company.employees) whoDoesWhat["salaries"] = null;
      if (company.vat) whoDoesWhat["vatStatement"] =  null;
      whoDoesWhat["yearlyAccounts"] = null;
      whoDoesWhat["taxReport"] = null;
      whoDoesWhat["shareholderReport"] = null;
      this.whoDoesWhat = whoDoesWhat;
      this.currentStep = 1;
    },
    setWhoDoesWhat(whoDoesWhat) {
      this.whoDoesWhat = whoDoesWhat;
    },
    setVoucherInfo(voucherInfo) {
      this.voucherInfo = voucherInfo;
    },
    setSalaryInfo(salaryInfo) {
      this.salaryInfo = salaryInfo;
    },
    setShareholderInfo(shareholderInfo) {
      this.shareholderInfo = shareholderInfo;
    },
    goToStep(step) {
      this.currentStep = step;
      if (step === 3) {
        const data = { company: this.company, whoDoesWhat: this.whoDoesWhat, voucherInfo: this.voucherInfo, salaryInfo: this.salaryInfo, shareholderInfo: this.shareholderInfo};
        calculatePrice(data)
            .then((data) => {
              this.estimatedPrice = { perMonth: data.data.price_month, perYear: data.data.price_year };
            })
            .catch((err) => {
              alert(err.message);
              this.goToStep(2);
            });
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

:root {
  --rem: 1px;
}

@media(max-width: 576px) {
  :root {
    --rem: 0.9px;
  }
}

.initial-styles {
  all: initial;
  * {
    all: unset;
  }

  div {
    display: block;
    position: relative;
  }
}

#price-calculator {
  font-family: 'Inter', sans-serif;
  font-size: calc(var(--rem) * 16);
  text-align: center;
  color: black;
  position: absolute;
  width: 100%;

  .card {
    background: #FFFFFF;
    box-shadow: 0px -4px 40px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    padding: calc(var(--rem) * 33) calc(var(--rem) * 40);
    @media(max-width: 576px) {
      padding: calc(var(--rem) * 29) calc(var(--rem) * 24);
    }
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-top: calc(var(--rem) * 24);
    max-width: 584px;
    width: 90vw;
    margin-left: 50vw;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;

    .card-title {
      display: flex;
      flex-basis: 100%;
      cursor: pointer;
      justify-content: space-between;
    }
  }

  .flex-break {
    flex-basis: 100%;
    width: 0;
  }

  .additional-info {
    font-weight: 700;
    margin-top: calc(var(--rem) * 37);
    max-width: 584px;
    width: 90vw;
    margin-left: 50vw;
    transform: translateX(-50%);
    text-align: left;
  }
}
</style>
