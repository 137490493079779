export default {
    header: {
        main: "Price calculator",
        sub: "Configure and estimate the costs for Acme products" ,
    },
    companyInformation: {
        title: "Company information",
        search: "Search a company or enter manually",
        next: "Next",
        noResults: "No companies found",
        companyTypeTitle: "Company type",
        companyTypes: [
            {value: "Limited", name: "Limited"},
        ],
        companyVatTitle: "VAT-Registered",
        companyEmployeesTitle: "Employees",
    },
    whoDoesWhat: {
        title: "Who does what",
        table: {
            topic: "Topic",
            accountingCompany: "Accounting company",
            vouchers: "Posting vouchers",
            salaries: "Process salaries",
            vatStatement: "Create VAT-Statement",
            yearlyAccounts: "Create yearly accounts",
            taxReport: "Create tax report",
            shareholderReport: "Create shareholder report",
        }
    },
    additionalInformation: "Additional information",
    voucherInfo: {
        title: "Vouchers",
        numberOfVouchers: "Number of vouchers",
        complexAccounting: "We use department-, storage-, and/or project-accounting",
        cashSales: "The company has cash sales",
        postingRevisions: {
            subtitle: "How often should the accounting company review the posting you do?",
            continuous: "Continuous",
            periodic: "Periodically / Every second month",
            yearly: "Yearly",
        }
    },
    salaryInfo: {
        title: "Salaries",
        paycheckNumber: "Number of pay check (per month)",
        claimsNumber: "Number of travel expenses/reimbursements claims (per month)",
    },
    shareholderInfo: {
        title: "Shareholders",
        numberOfShareholders: "Number of shareholders",
    },
    estimatedPrice: {
        title: "Estimated price",
        contactManager: "Contact manager",
        perMonth: "Per month",
        perYear: "Per year",
        exVat: "Prices ex. VAT"
    },
    select: {
        yesNo: [
            {value: true, name: "Yes"},
            {value: false, name: "No"}
        ],
    },
    calculatePrice: "Calculate price",
}
