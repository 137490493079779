<template>
  <div class="header">
    <h1>{{ translations.header.main }}</h1>
    <h2>{{ translations.header.sub }}</h2>
  </div>
</template>

<script>
import {translations} from "@/utils";

export default {
  name: 'Header',
  data() {
    return {
      translations: translations
    }
  }
}
</script>

<style lang="scss" scoped>
#price-calculator {
  .header {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: calc(var(--rem) * 464);
    background-image: url('~@/assets/header-bg.png');
    background-size: cover;
    background-position: center;
    @media(max-width: 576px) {
      background-position-x: 20%;
      padding: 20px;
    }
    position: absolute;

    h1 {
      font-size: calc(var(--rem) * 64);
      margin-top: calc(var(--rem) * 81);
      @media(max-width: 576px) {
        font-size: calc(var(--rem) * 32);
        margin-top: calc(var(--rem) * 120);
      }
      font-weight: 700;
      color: #FFFFFF;
      display: block;
      text-align: center;
    }

    h2 {
      font-size: calc(var(--rem) * 24);
      @media(max-width: 576px) {
        font-size: calc(var(--rem) * 17);
      }
      margin-top: calc(var(--rem) * 16);
      font-weight: 400;
      color: #FFFFFF;
      display: block;
      text-align: center;
    }
  }
}
</style>
